// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgUserCircle = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25m0 5.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M15.75 9c0 1.12-.49 2.124-1.268 2.812A5.75 5.75 0 0 1 17.75 17v1a.75.75 0 0 1-1.5 0v-1a4.25 4.25 0 0 0-8.5 0v1a.75.75 0 0 1-1.5 0v-1a5.75 5.75 0 0 1 3.268-5.188A3.75 3.75 0 1 1 15.75 9"
      clipRule="evenodd"
    />
  </svg>
);
export const UserCircleIcon = forwardRef(SvgUserCircle);
