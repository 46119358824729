// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.75 5c0-.69.56-1.25 1.25-1.25h10c.69 0 1.25.56 1.25 1.25v1a.75.75 0 0 0 1.5 0V5A2.75 2.75 0 0 0 17 2.25H7A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19v-1a.75.75 0 0 0-1.5 0v1c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25zm10.78 10.53 3-3a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H12a.75.75 0 0 0 0 1.5h5.19l-1.72 1.72a.75.75 0 1 0 1.06 1.06"
      clipRule="evenodd"
    />
  </svg>
);
export const LogoutIcon = forwardRef(SvgLogout);
